import React from 'react';
import ReactDOM from 'react-dom/client';
import Component from './portfolio.tsx'; // Adjust the path as necessary

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Component /> {/* Render your component here */}
  </React.StrictMode>
);

