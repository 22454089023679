"use client"

import React, { useState, useEffect } from 'react'
import { Cpu, Clock, Award, DollarSign, Send, Loader2, Menu, X } from 'lucide-react'
import { motion } from 'framer-motion'

const PricingCard: React.FC<{
  title: string,
  price: string,
  features: string[],
  recommended?: boolean,
  promotion?: string
}> = ({ title, price, features, recommended, promotion }) => (
  <motion.div 
    className={`bg-black rounded-lg shadow-lg overflow-hidden ${recommended ? 'border-4 border-purple-500' : ''} relative`}
    initial={{ opacity: 0, y: 50 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
    whileHover={{ scale: 1.05 }}
  >
    {recommended && (
      <div className="bg-purple-500 text-white text-center py-2 font-semibold">
        Recommended
      </div>
    )}
    {promotion && (
      <div className="absolute top-0 right-0 bg-yellow-400 text-gray-800 text-xs font-bold px-3 py-1 transform rotate-45 translate-x-8 translate-y-4">
        {promotion}
      </div>
    )}
    <div className="p-6">
      <h3 className="text-2xl font-bold text-white mb-4">{title}</h3>
      <div className="text-4xl font-bold text-purple-400 mb-6">{price}</div>
      <ul className="space-y-2 mb-6">
        {features.map((feature, index) => (
          <li key={index} className="flex items-center text-gray-300">
            <svg className="w-5 h-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
            </svg>
            {feature}
          </li>
        ))}
      </ul>
      <button className="w-full bg-purple-600 text-white py-2 px-4 rounded-md hover:bg-purple-700 transition duration-300 transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50">
        Choose Plan
      </button>
    </div>
  </motion.div>
)

interface FormState {
  name: string
  email: string
  subject: string
  message: string
}

interface FormErrors {
  name?: string
  email?: string
  subject?: string
  message?: string
}

export default function Portfolio() {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [formState, setFormState] = useState<FormState>({
    name: '',
    email: '',
    subject: '',
    message: ''
  })
  const [errors, setErrors] = useState<FormErrors>({})
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [submitStatus, setSubmitStatus] = useState<'idle' | 'success' | 'error'>('idle')

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target
    setFormState(prev => ({ ...prev, [name]: value }))
    setErrors(prev => ({ ...prev, [name]: undefined }))
  }

  const validateForm = (): boolean => {
    const newErrors: FormErrors = {}
    if (!formState.name.trim()) newErrors.name = 'Name is required'
    if (!formState.email.trim()) newErrors.email = 'Email is required'
    else if (!/\S+@\S+\.\S+/.test(formState.email)) newErrors.email = 'Email is invalid'
    if (!formState.subject.trim()) newErrors.subject = 'Subject is required'
    if (!formState.message.trim()) newErrors.message = 'Message is required'

    setErrors(newErrors)
    return Object.keys(newErrors).length === 0
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!validateForm()) return;
  
    setIsSubmitting(true);
    setSubmitStatus('idle');
  
    try {
      const response = await fetch('/api/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formState),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      console.log('Success:', data);
      setSubmitStatus('success');
      setFormState({ name: '', email: '', subject: '', message: '' });
    } catch (error) {
      console.error('Submission error:', error);
      setSubmitStatus('error');
    } finally {
      setIsSubmitting(false);
    }
  };
  
  return (
    <div className="min-h-screen bg-black text-white">
      <header className="sticky top-0 bg-black z-10 shadow-md">
        <nav className="container mx-auto px-6 py-3">
          <div className="flex justify-between items-center">
            <a href="#" className="text-2xl font-bold text-purple-400">DevFreelancer</a>
            <div className="hidden md:flex space-x-4">
              <a href="#about" className="text-gray-300 hover:text-white transition duration-300">About</a>
              <a href="#services" className="text-gray-300 hover:text-white transition duration-300">Services</a>
              <a href="#why-us" className="text-gray-300 hover:text-white transition duration-300">Why Us</a>
              <a href="#contact" className="text-gray-300 hover:text-white transition duration-300">Contact</a>
            </div>
            <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="md:hidden text-white">
              {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
            </button>
          </div>
        </nav>
        {isMenuOpen && (
          <div className="md:hidden bg-gray-800">
            <a href="#about" className="block py-2 px-4 text-sm text-gray-300 hover:bg-gray-700">About</a>
            <a href="#services" className="block py-2 px-4 text-sm text-gray-300 hover:bg-gray-700">Services</a>
            <a href="#why-us" className="block py-2 px-4 text-sm text-gray-300 hover:bg-gray-700">Why Us</a>
            <a href="#contact" className="block py-2 px-4 text-sm text-gray-300 hover:bg-gray-700">Contact</a>
          </div>
        )}
      </header>

      <main>
        <section className="hero container mx-auto px-6 py-32 text-center relative overflow-hidden"
          style={{
            backgroundImage: `url('https://images.unsplash.com/photo-1451187580459-43490279c0fa?ixlib=rb-1.2.1&auto=format&fit=crop&w=1920&q=80')`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <div className="absolute inset-0 bg-black opacity-75"></div>
          <div className="relative">
            <motion.h1 
              className="text-4xl sm:text-5xl md:text-7xl font-bold mb-4 bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600 text-transparent leading-tight pb-2.5"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              Crafting Digital Masterpieces
            </motion.h1>
            <motion.p 
              className="text-xl mb-8 text-gray-300"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              Freelance Web Developer specializing in modern, responsive websites with AI integration
            </motion.p>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.4 }}
            >
              <a 
                href="#contact" 
                className="relative inline-flex items-center px-12 py-3 overflow-hidden text-lg font-medium text-white bg-purple-600 rounded-full hover:bg-purple-700 group"
              >
                <span className="absolute left-0 block w-full h-0 transition-all bg-indigo-600 opacity-100 group-hover:h-full top-1/2 group-hover:top-0 duration-400 ease"></span>
                <span className="absolute right-0 flex items-center justify-start w-10 h-10 duration-300 transform translate-x-full group-hover:translate-x-0 ease">
                  <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path>
                  </svg>
                </span>
                <span className="relative">Get in Touch</span>
              </a>
            </motion.div>
          </div>
        </section>

        <section id="about" className="container mx-auto -mt-6 px-6 py-16 bg-black rounded-lg shadow-lg mb-36">
          <h2 className="text-3xl font-bold mb-8 text-center text-purple-400">About Me</h2>
          <div className="max-w-3xl mx-auto text-center">
            <p className="mb-4 text-gray-300">Hello! I'm a passionate freelance web developer with a solid experience in creating beautiful, functional websites. I specialize in React, Next.js, Bootstrap, and Tailwind CSS to build modern web applications that are both performant and visually appealing.</p>
            <p className="text-gray-300">My goal is to help businesses and individuals establish a strong online presence through custom-tailored web solutions. Let's work together to bring your digital vision to life!</p>
          </div>
        </section>

        <section id="services" className="container mx-auto -mt-32 px-6 py-16 bg-gray-900">
          <h2 className="text-3xl font-bold mb-12 text-center text-purple-400">Services</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="service-card bg-black rounded-lg shadow-lg overflow-hidden transform transition duration-300 hover:scale-105">
              <div className="p-6">
                <div className="w-16 h-16 bg-purple-900 rounded-full flex items-center justify-center mb-4 mx-auto">
                  <i className="bi bi-code-slash text-purple-400 text-3xl"></i>
                </div>
                <h3 className="text-xl font-semibold mb-2 text-center text-white">Web Development</h3>
                <p className="text-gray-400 text-center">Custom website development using the latest technologies and best practices.</p>
              </div>
              <div className="bg-purple-600 text-white p-4 text-center">
                <a href="#contact" className="hover:underline">Learn More</a>
              </div>
            </div>
            <div className="service-card bg-black rounded-lg shadow-lg overflow-hidden transform transition duration-300 hover:scale-105">
              <div className="p-6">
                <div className="w-16 h-16 bg-pink-900 rounded-full flex items-center justify-center mb-4 mx-auto">
                  <i className="bi bi-palette text-pink-400 text-3xl"></i>
                </div>
                <h3 className="text-xl font-semibold mb-2 text-center text-white">UI/UX Design</h3>
                <p className="text-gray-400 text-center">Creating intuitive and visually appealing user interfaces for optimal user experience.</p>
              </div>
              <div className="bg-pink-600 text-white p-4 text-center">
                <a href="#contact" className="hover:underline">Learn More</a>
              </div>
            </div>
            <div className="service-card bg-black rounded-lg shadow-lg overflow-hidden transform transition duration-300 hover:scale-105">
              <div className="p-6">
                <div className="w-16 h-16 bg-blue-900 rounded-full flex items-center justify-center mb-4 mx-auto">
                  <i className="bi bi-graph-up text-blue-400 text-3xl"></i>
                </div>
                <h3 className="text-xl font-semibold mb-2 text-center text-white">SEO Optimization</h3>
                <p className="text-gray-400 text-center">Improving your website's visibility and ranking in search engine results.</p>
              </div>
              <div className="bg-blue-600 text-white p-4 text-center">
                <a href="#contact" className="hover:underline">Learn More</a>
              </div>
            </div>
          </div>
        </section>

        <section id="why-us" className="container mx-auto px-6 py-16 bg-gray-900 text-white rounded-lg shadow-lg mb-16">
          <h2 className="text-3xl font-bold mb-16 text-center">Why Choose Us</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            <div className="text-center">
              <Cpu className="w-12 h-12 mx-auto mb-4 text-purple-400" />
              <h3 className="text-xl font-semibold mb-2">AI Integration</h3>
              <p className="text-gray-300">Leveraging cutting-edge AI technologies to enhance your web experience.</p>
            </div>
            <div className="text-center">
              <Clock className="w-12 h-12 mx-auto mb-4 text-purple-400" />
              <h3 className="text-xl font-semibold mb-2">Fast Delivery</h3>
              <p className="text-gray-300">Committed to delivering your project in the shortest possible timeframe.</p>
            </div>
            <div className="text-center">
              <Award className="w-12 h-12 mx-auto mb-4 text-purple-400" />
              <h3 className="text-xl font-semibold  mb-2">Quality Work</h3>
              <p className="text-gray-300">Providing high-quality websites that exceed your expectations.</p>
            </div>
            <div className="text-center">
              <DollarSign className="w-12 h-12 mx-auto mb-4 text-purple-400" />
              <h3 className="text-xl font-semibold mb-2">Competitive Pricing</h3>
              <p className="text-gray-300">Offering top-notch services at budget-friendly prices.</p>
            </div>
          </div>
        </section>

        <section className="py-20 -mt-32 bg-gray-900">
          <div className="container mx-auto px-4">
            <motion.h2 
              className="text-4xl font-bold text-center text-purple-400 mb-12"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              Choose Your Perfect Plan
            </motion.h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              <PricingCard
                title="Basic Landing Page"
                price="$850 - $1,500"
                features={[
                  "1-3 pages",
                  "Responsive design",
                  "Very fast delivery time",
                  "Contact form integration"
                ]}
                promotion="10% OFF"
              />
              <PricingCard
                title="Advanced Landing Page"
                price="$1,800 - $2,500"
                features={[
                  "Up to 6 pages",
                  "Responsive design",
                  "Basic SEO optimization",
                  "Maintenance included",
                  "Free future feature updates",
                  "Troubleshooting support"
                ]}
                recommended={true}
                promotion="15% OFF"
              />
              <PricingCard
                title="Ultimate Package"
                price="$3,000 - $6,500"
                features={[
                  "Custom website development",
                  "All Advanced features included",
                  "Advanced SEO optimization",
                  "Hosting setup and management",
                  "Domain name assistance",
                  "SSL Certificate setup",
                  "Ongoing support and guidance"
                ]}
              />
            </div>
          </div>
        </section>

        <section id="contact" className="py-16 bg-gray-900">
          <div className="container mx-auto px-4">
            <motion.h2 
              className="text-4xl font-bold text-center text-purple-400 mb-12"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              Get in Touch
            </motion.h2>
            <div className="max-w-4xl mx-auto bg-gray-800 rounded-lg shadow-xl overflow-hidden">
              <div className="flex flex-col md:flex-row">
                <div className="md:w-1/2 bg-black text-white p-8">
                  <motion.h3 
                    className="text-2xl font-semibold mb-4"
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.5, delay: 0.2 }}
                  >
                    Let's talk about everything!
                  </motion.h3>
                  <motion.p 
                    className="mb-6 text-gray-300"
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.5, delay: 0.3 }}
                  >
                    Have questions? Need a quote? Want to discuss a project? Don't hesitate to reach out!
                  </motion.p>
                 
                  <motion.div 
                    className="flex items-center mb-4"
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.5, delay: 0.5 }}
                  >
                    <i className="bi bi-telephone text-2xl mr-4 text-purple-400"></i>
                    <span className="text-gray-300">+212 779-557756</span>
                  </motion.div>
                  <motion.div 
                    className="flex items-center"
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.5, delay: 0.6 }}
                  >
                    <i className="bi bi-envelope text-2xl mr-4 text-purple-400"></i>
                    <span className="text-gray-300">dev.freelancer.contact@gmail.com</span>
                  </motion.div>
                </div>
                <div className="md:w-1/2 p-8">
                  <form onSubmit={handleSubmit} className="space-y-6">
                    <motion.div
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.5, delay: 0.2 }}
                    >
                      <label htmlFor="name" className="block text-sm font-medium text-gray-300 mb-1">Name</label>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        value={formState.name}
                        onChange={handleInputChange}
                        className={`w-full px-3 py-2 bg-gray-700 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-purple-500 text-white transition duration-300 ${
                          errors.name ? 'border-red-500' : 'border-gray-600'
                        }`}
                        required
                      />
                      {errors.name && <p className="mt-1 text-xs text-red-500">{errors.name}</p>}
                    </motion.div>
                    <motion.div
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.5, delay: 0.3 }}
                    >
                      <label htmlFor="email" className="block text-sm font-medium text-gray-300 mb-1">Email</label>
                      <input
                        type="email"
                        id="email"
                        name="email"
                        value={formState.email}
                        onChange={handleInputChange}
                        className={`w-full px-3 py-2 bg-gray-700 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-purple-500 text-white transition duration-300 ${
                          errors.email ? 'border-red-500' : 'border-gray-600'
                        }`}
                        required
                      />
                      {errors.email && <p className="mt-1 text-xs text-red-500">{errors.email}</p>}
                    </motion.div>
                    <motion.div
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.5, delay: 0.4 }}
                    >
                      <label htmlFor="subject" className="block text-sm font-medium text-gray-300 mb-1">Subject</label>
                      <input
                        type="text"
                        id="subject"
                        name="subject"
                        value={formState.subject}
                        onChange={handleInputChange}
                        className={`w-full px-3 py-2 bg-gray-700 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-purple-500 text-white transition duration-300 ${
                          errors.subject ? 'border-red-500' : 'border-gray-600'
                        }`}
                        required
                      />
                      {errors.subject && <p className="mt-1 text-xs text-red-500">{errors.subject}</p>}
                    </motion.div>
                    <motion.div
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.5, delay: 0.5 }}
                    >
                      <label htmlFor="message" className="block text-sm font-medium text-gray-300 mb-1">Message</label>
                      <textarea
                        id="message"
                        name="message"
                        rows={4}
                        value={formState.message}
                        onChange={handleInputChange}
                        className={`w-full px-3 py-2 bg-gray-700 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-purple-500 text-white transition duration-300 ${
                          errors.message ? 'border-red-500' : 'border-gray-600'
                        }`}
                        required
                      ></textarea>
                      {errors.message && <p className="mt-1 text-xs text-red-500">{errors.message}</p>}
                    </motion.div>
                    <motion.div
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.5, delay: 0.6 }}
                    >
                      <button
        type="submit"
        disabled={isSubmitting}
        className="w-full bg-purple-600 text-white py-2 px-4 rounded-md hover:bg-purple-700 transition duration-300 transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center"
      >
        {isSubmitting ? (
          <Loader2 className="animate-spin mr-2" size={20} />
        ) : (
          <Send className="mr-2" size={20} />
        )}
        {isSubmitting ? 'Sending...' : 'Send Message'}
      </button>
      {submitStatus === 'success' && (
        <div className="mt-4 p-3 bg-green-900 text-green-300 rounded-md">
          Thank you for your message! We'll get back to you soon.
        </div>
      )}
      {submitStatus === 'error' && (
        <div className="mt-4 p-3 bg-red-900 text-red-300 rounded-md">
          Oops! Something went wrong. Please try again later.
        </div>
      )}
                    </motion.div>
                  </form>
                  {submitStatus === 'success' && (
                    <motion.div
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      className="mt-4 p-3 bg-green-900 text-green-300 rounded-md"
                    >
                      Thank you for your message! We'll get back to you soon.
                    </motion.div>
                  )}
                  {submitStatus === 'error' && (
                    <motion.div
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      className="mt-4 p-3 bg-red-900 text-red-300 rounded-md"
                    >
                      Oops! Something went wrong. Please try again later.
                    </motion.div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <footer className="bg-gray-900 text-white py-8">
        <div className="container mx-auto px-6 text-center">
          <p>&copy; 2024 DevFreelancer. All rights reserved.</p>
          <div className="mt-4">
            <a href="#" className="text-gray-400 hover:text-white mx-2 transition duration-300"><i className="bi bi-facebook"></i></a>
            <a href="#" className="text-gray-400 hover:text-white mx-2 transition duration-300"><i className="bi bi-twitter"></i></a>
            <a href="https://www.linkedin.com/in/aymane-harriga-815899331/" className="text-gray-400 hover:text-white mx-2 transition duration-300"><i className="bi bi-linkedin"></i></a>
            <a href="https://github.com/FreelancerDev4u/PublicProjects" className="text-gray-400 hover:text-white mx-2 transition duration-300"><i className="bi bi-github"></i></a>
          </div>
        </div>
      </footer>
    </div>
  )
}